
import BasePickupInfo from '~/components/base/BasePickupInfo.vue';
import { ALL_COUNTRIES } from '~/vars/all_countries';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  components: { BasePickupInfo },
  mixins: [],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    typeShipping: {
      type: String,
      required: true,
    },
    offering: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: null,
      filteredCountries: [],
      formatedCountries: [],
    };
  },
  computed: {
    shouldShowModal: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit('update:showModal', value);
      },
    },
    cols() {
      return this.$vuetify.breakpoint.smAndDown ? 1 : 3;
    },

    title() {
      return this.$t(`market.product.shipping.${this.typeShipping}.title`);
    },
    subtitle() {
      return this.$t(`market.product.shipping.${this.typeShipping}.subtitle`);
    },
  },
  watch: {},
  methods: {
    slicedCountries(n) {
      if (this.cols === 3)
        return this.filteredCountries.slice(
          (this.filteredCountries.length / 4 < 12
            ? 4
            : this.filteredCountries.length / this.cols) *
            (n - 1),
          (this.filteredCountries.length / 4 < 12
            ? 4
            : this.filteredCountries.length / this.cols) * n,
        );
      return this.filteredCountries;
    },

    closeModal() {
      this.shouldShowModal = false;
    },
    filter(value) {
      this.filteredCountries = this.formatedCountries.filter(
        ({ code, country }) =>
          code.toLowerCase().includes(value.toLowerCase()) ||
          country.toLowerCase().includes(value.toLowerCase()),
      );
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {
    if (this.typeShipping === 'delivery') {
      this.formatedCountries = this.offering.selectedcountries.map((item) => {
        return {
          code: item,
          country: ALL_COUNTRIES.find(({ code }) => code === item).country,
        };
      });
      this.filteredCountries = [...this.formatedCountries];
    }
  },
  updated() {},
};
