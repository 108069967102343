
import 'vue-tel-input/dist/vue-tel-input.css';
import { ALL_COUNTRIES } from '~/vars/all_countries';
export default {
  components: {},
  mixins: [],
  props: {
    postalAddress: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    country() {
      return ALL_COUNTRIES.find(
        ({ code }) => code === this.postalAddress.country,
      ).country;
    },
    address() {
      return `${
        this.postalAddress.fullname ? this.postalAddress.fullname + ', ' : ''
      }${this.postalAddress.line1}${
        this.postalAddress.line2 ? ', ' + this.postalAddress.line2 : ''
      }, ${this.postalAddress.city}, ${this.postalAddress.stateprovince}, ${
        this.postalAddress.postalcode
      }.`;
    },
    instructions() {
      return this.postalAddress.instructions;
    },
  },
  watch: {},
  methods: {
    openAddress() {
      let params = Object.entries(this.postalAddress).reduce(
        (params, field) => {
          if (!['instructions', 'id'].includes(field[0]) && field[1] !== null)
            params = `${params !== '' ? params + ',' : params}${field[1]}`;
          return params;
        },
        '',
      );
      window.open(
        `https://www.google.com/maps/search/${encodeURIComponent(params)}`,
        '_blank',
      );
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {},
  updated() {},
};
